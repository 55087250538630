<template>
<div class="faq">
  <div v-for="(item, index) in faqs" :key="index" class="dropdown-faq">
    <div class="faq-title" @click="toggleFaq(index)">
      <h3>{{ item.question }}</h3>
      <img src="../assets/down.svg" alt="down" :class="{rotate: activeIndex===index}" loading="lazy">
    </div>

    <p v-if="activeIndex === index" class="content" v-html="item.answer">
    </p>
  </div>
</div>
</template>

<script>
export default {
  props: {
    faqs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeIndex: null
    }
  },
  methods: {
    toggleFaq(index) {
      console.log(index);
      if (this.activeIndex === index) {
        this.activeIndex = null
      } else {
        this.activeIndex = index
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.faq {
  padding: 0 10px;
  margin-bottom: 30px;

  h3 {
    font-size: 19px;
    margin-bottom: 16px;
    margin-top: 16px;
    font-weight: 500;
  }

  .dropdown-faq {
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
    padding-left: 14px;

    .faq-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 16px;
        height: 16px;
        margin-right: 20px;
        transition: transform 0.3s ease;
      }

      .rotate {
        transform: rotate(180deg);
        animation: rotateOut 0.3s ease forwards;
      }
    }

  }

  p {
    margin-left: 2px;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.1px;
  }
}
</style>
